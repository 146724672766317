.errorlogs-class {
    width: calc(100% - 20px);
    margin: 10px;
    border: none;
}

/** This is so position sticky works for table headers */
.MuiPaper-root {
    overflow: unset !important;
}
